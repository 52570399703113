<template>
  <!-- Product search -->
  <el-dialog
    title="Бүтээгдэхүүн сонгох"
    :visible.sync="show"
    @close="closeDialog"
    width="600px"
  >
    <div>
      <el-input
        placeholder="Барааны нэр, SKU ..."
        v-model="searchText"
        @input="searchProduct"
      >
        <el-button slot="prepend" icon="el-icon-search" @click="searchProduct"></el-button>
      </el-input>
    </div>
    <div class="mt20" v-loading="productLoading">
      <ul class="transfer-product-list" v-if="products.length > 0">
        <li v-for="product in products" :key="product.productId">
          <el-row :gutter="10" type="flex" align="middle">
            <el-col :span="3">
              <div class="image-holder">
                <img :src="product.image" alt="">
              </div>
            </el-col>
            <el-col :span="19">
              {{product.name_mon}}
            </el-col>
            <el-col :span="2" class="text-right">
              <el-checkbox v-model="product.checked" :indeterminate="product.isIndeterminate" @change="checkProduct(product)"></el-checkbox>
            </el-col>
          </el-row>
          <ul>
            <li v-for="variant in product.variants" :key="variant.variant_id">
              <el-row :gutter="20" type="flex" align="middle">
                <el-col :span="14" :offset="2">
                  <div>
                    {{variant.variant_name}}
                  </div>
                  <div class="text-color-secondary">
                    {{variant.variant_sku}}
                  </div>
                </el-col>
                <el-col :span="4">
                  {{variant.variant_sellPrice}}₮
                </el-col>
                <el-col :span="2">
                  <div class="text-center">
                    {{variant.availableCount}} ш
                  </div>
                </el-col>
                <el-col :span="2" class="text-right">
                  <el-checkbox v-model="variant.checked" @change="checkVariant(product, variant)"></el-checkbox>
                </el-col>
              </el-row>
            </li>
          </ul>
        </li>
      </ul>
      <div v-else class="text-center mtb30">
        <div class="text-color-placeholder">
          <p>
            Бараа байхгүй байна
          </p>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">Cancel</el-button>
      <el-button type="success" @click="returnProducts">
        Бүтээгдэхүүн нэмэх
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import services from '../../../../helpers/services'
export default {
  name: 'productSearchDialog',
  props: [
    'visibleProductDialog'
  ],
  data () {
    return {
      searchText: '',
      products: [],
      productLoading: false,
      show: this.visibleProductDialog
    }
  },
  methods: {
    closeDialog () {
      this.$emit('update:visibleProductDialog', false)
    },
    checkProduct (product) {
      product.variants.forEach(variant => {
        variant.checked = product.checked
      })
    },
    checkVariant (product, variant) {
      let checkedCount = 0
      product.variants.forEach(variant => {
        if (variant.checked) {
          checkedCount = checkedCount + 1
        }
      })
      product.checked = checkedCount === product.variants.length
      product.isIndeterminate = checkedCount > 0 && checkedCount < product.variants.length
    },
    searchProduct () {
      if (this.searchText.length >= 0) {
        this.productLoading = true
        this.visibleProductDialog = true
        services.getProductsWithVariant(this.searchText).then(response => {
          if (Array.isArray(response) && response.length > 0) {
            response.forEach(product => {
              const property = {
                checked: false,
                isIndeterminate: false
              }
              Object.assign(product, property, product)
              product.variants.forEach(variant => {
                const property = {
                  checked: false,
                  number_tobe_transferred: 1
                }
                Object.assign(variant, property, variant)
              })
            })
            this.products = response
          }
          this.productLoading = false
        })
      }
    },
    returnProducts () {
      const tempProducts = []
      this.products.forEach(product => {
        const variants = [...product.variants.filter(variant => variant.checked)]
        if (variants.length > 0) {
          product.variants = variants
          tempProducts.push(product)
        }
      })
      if (tempProducts.length > 0) {
        this.$emit('update:products', tempProducts)
        this.show = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'bottom-left',
          message: 'Бүтээгдэхүүн сонгоно уу',
          duration: 2000
        })
      }
    }
  }
}
</script>
