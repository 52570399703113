<template>
  <div>
    <!-- fixed top header -->
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button v-if="activeStep > 1" type="warning" @click="prevStep" icon="el-icon-arrow-left">Өмнөх</el-button>
          <el-button v-if="activeStep < 3" type="primary" @click="nextStep">Дараах <i class="el-icon-arrow-right el-icon-right"></i></el-button>
          <el-button v-if="activeStep===3"  type="success" @click="saveDiscount('discountForm')">Хадгалах</el-button>
        </el-col>
      </el-row>
    </div>
    <el-steps :space="200" :active="activeStep" finish-status="success"  align-center  style="justify-content: center">
      <el-step title="Үндсэн мэдээлэл"></el-step>
      <el-step title="Нөхцөл"></el-step>
      <el-step title="Урамшуулал"></el-step>
    </el-steps>
    <el-form label-position="top" :model="discountForm" ref="discountForm" :rules="discountFormRules">
      <el-row type="flex" justify="center">
        <el-col :xs="24" :sm="24" :md="24" :lg="18" :xl="14">
          <!-- Бүх хуудсанд ашиглагдах header section -->
          <custom-header title="Урамшуулал үүсгэх"/>
          <!-- form error template -->
          <div class="form-error-container mb20" v-if="errorArray.length != 0">
            <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
            <ul class="error-list">
              <div v-for="(error, index) in errorArray" :key="index">
                <li>{{error.value}}</li>
              </div>
            </ul>
          </div>
          <el-row :gutter="20">
            <el-col :span="16">
              <div v-if="activeStep===1" class="panel">
                <div class="panel-item">
                  <header>Урамшуулалын хэлбэр</header>
                </div>
                <div class="panel-item">
                  <el-radio-group v-model="discountForm.type">
                    <el-radio class="block mb20" v-for="type in discountTypes" :key="type.value" :label="type.value">{{type.label}}</el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div class="panel" v-if="discountForm.type === 'DISCOUNT_CODE' && activeStep===1">
                <div class="panel-item">
                  <header>Хямдралын код, Цацах суваг</header>
                </div>
                <div class="panel-item">
                  <el-form-item label="Купон код" prop="discount_code">
                    <el-input v-model="discountForm.discount_code" placeholder="DELIVERY_ZERO..."></el-input>
                    <span class="tip-text">Customers will enter this discount code at checkout.</span>
                  </el-form-item>
                  <div class="text-right">
                    <span class="text-primary cursor-pointer" @click="generateCode">Код автоматаар үүсгэх</span>
                  </div>
                  <el-form-item label="Цацах суваг" prop="channel">
                      <el-input v-model="discountForm.channel" placeholder="TOKTOKMALL"></el-input>
                    </el-form-item>
                  </div>
                </div>
              <div v-if="activeStep===1" class="panel">
                <div class="panel-item">
                  <header>Урамшуулалын нэр, тайлбар</header>
                </div>
                  <el-row :gutter="20">
                    <el-col :span="12">
                      <div class="panel-item">
                        <el-form-item label="Монгол нэр" prop="title_mon">
                          <el-input v-model="discountForm.title_mon" placeholder="Наадам"></el-input>
                        </el-form-item>
                      </div>
                  </el-col>
                  <el-col :span="12">
                      <div class="panel-item">
                        <el-form-item label="Англи нэр" prop="title_eng">
                          <el-input v-model="discountForm.title_eng" placeholder="Naadam"></el-input>
                        </el-form-item>
                      </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                      <div class="panel-item">
                        <el-form-item label="Монгол тайлбар" prop="desc_mon">
                          <el-input v-model="discountForm.desc_mon"></el-input>
                        </el-form-item>
                      </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                      <div class="panel-item">
                        <el-form-item label="Англи тайлбар" prop="desc_eng">
                          <el-input v-model="discountForm.desc_eng"></el-input>
                        </el-form-item>
                      </div>
                  </el-col>
                </el-row>
              </div>
              <div v-if="activeStep===3" class="panel">
                <div class="panel-item">
                  <header>Урамшуулалын төрөл</header>
                </div>
                <div class="panel-item">
                  <el-radio-group v-model="discountForm.discount_type">
                    <el-radio class="block mb20" v-for="type in discountSubTypes" :key="type.value" :label="type.value">{{type.label}}</el-radio>
                  </el-radio-group>
                </div>
                <div class="panel-item" v-if="discountForm.discount_type === 'PERCENTAGE' || discountForm.discount_type === 'FIXED_AMOUNT'">
                  <el-form-item label="Хямдрах дүн" required>
                    <el-input  v-if="discountForm.discount_type === 'PERCENTAGE'" type="number" :min="1" :max="100" v-model="discountForm.discount.saled_percentage" style="max-width: 300px">
                      <template slot="append">%</template>
                    </el-input>
                    <el-input v-if="discountForm.discount_type === 'FIXED_AMOUNT'" type="number" v-model="discountForm.discount.saled_price" style="max-width: 300px">
                      <template slot="append">₮</template>
                    </el-input>
                  </el-form-item>
                </div>
                <div class="panel-item">
                  <div v-if="discountForm.discount_type === 'PRODUCT'">
                    <div>
                      <el-button
                        icon="el-icon-search"
                        @click="visibleProductDialog1 = true"
                      >Бүтээгдэхүүн сонгох</el-button>
                    </div>
                    <div v-if="discountForm.freeProducts.length > 0 && discountForm.discount_type === 'PRODUCT'">
                      <div v-for="product in discountForm.freeProducts" :key="product.productId">
                        <div v-for="variant in product.variants" :key="variant.variant_id">
                          <el-row :gutter="5" type="flex" align="middle" class="mt10 pt10 border-top">
                            <el-col :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                              <div class="image-holder">
                                <img :src="variant.variant_image" alt="">
                              </div>
                            </el-col>
                            <el-col :xl="14" :lg="14" :md="12" :sm="12" :xs="12">
                              <div class="text-color-secondary">{{product.name_mon}}</div>
                              <div>{{variant.variant_name}}</div>
                              <div class="text-color-placeholder">{{variant.variant_sku}}</div>
                            </el-col>
                            <el-col :xl="3" :lg="3" :md="3" :sm="3" :xs="3">
                              <el-input type="number" v-model="variant.count"></el-input>
                            </el-col>
                            <el-col :xl="3" :lg="3" :md="3" :sm="3" :xs="3">
                              <div class="text-right">
                                <i class="el-icon-close cursor-pointer" @click="deleteVariantFromDiscount(product, variant)"></i>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Юунд үйлчлэх -->
              <div v-if="activeStep===2"  class="panel">
                <div class="panel-item">
                  <header>Нөхцөл</header>
                </div>
                <!-- By Percentage, Fixed amount discount -->
                <div>
                  <div class="panel-item">
                    <el-form-item label="" style="margin-bottom: 0">
                      <el-radio-group v-model="discountForm.discount_item_type" class="mt20">
                        <el-radio class="block mb20" v-for="type in discountItemTypes" :key="type.value" :label="type.value">{{type.label}}</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <!-- discount_item_type == specific_product -->
                    <div v-if="discountForm.discount_item_type === 'specific_product'">
                      <div>
                        <el-button
                          icon="el-icon-search"
                          @click="visibleProductDialog = true"
                        >Бүтээгдэхүүн сонгох</el-button>
                      </div>
                      <div v-if="discountForm.products.length > 0 && discountForm.discount_item_type === 'specific_product'">
                        <div v-for="product in discountForm.products" :key="product.productId">
                          <div v-for="variant in product.variants" :key="variant.variant_id">
                            {{discountForm.discount_item_type}}
                            <el-row :gutter="5" type="flex" align="middle" class="mt10 pt10 border-top">
                              <el-col :xl="4" :lg="4" :md="6" :sm="6" :xs="6">
                                <div class="image-holder">
                                  <img :src="variant.variant_image" alt="">
                                </div>
                              </el-col>
                              <el-col :xl="16" :lg="16" :md="14" :sm="14" :xs="14">
                                <div class="text-color-secondary">{{product_mon}}</div>
                                <div>{{variant.variant_name}}</div>
                                <div class="text-color-placeholder">{{variant.variant_sku}}</div>
                              </el-col>
                              <el-col :xl="4" :lg="4" :md="4" :sm="4" :xs="4">
                                <div class="text-right">
                                  <i class="el-icon-close cursor-pointer" @click="deleteVariantFromDiscount(product, variant)"></i>
                                </div>
                              </el-col>
                            </el-row>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- discount_item_type == category -->
                    <div v-else-if="discountForm.discount_item_type === 'category'">
                      <el-input
                        placeholder="Эрэгтэй, эмэгтэй, гутал ..."
                        v-model="treeFilterText"
                      >
                        <el-button slot="prepend" icon="el-icon-search"></el-button>
                      </el-input>
                      <el-tree
                        :data="categories"
                        :props="treeDefaultProps"
                        :filter-node-method="filterNode"
                        show-checkbox
                        ref="tree"
                        class="mt20">
                      </el-tree>
                    </div>
                    <!-- discount_item_type == supplier -->
                    <div v-else-if="discountForm.discount_item_type === 'supplier'">
                      <div>
                        <el-button icon="el-icon-search" @click="visibleSupplierDialog = true">Нийлүүлэгч сонгох</el-button>
                      </div>
                      <div v-if="discountForm.suppliers.length > 0">
                        <div v-for="item in discountForm.suppliers" :key="item.supplier_id">
                          <el-row :gutter="20" class="border-top pt20 mt20">
                            <el-col :span="20">
                              {{item.supplier_monName}}
                            </el-col>
                            <el-col :span="4">
                              <div class="text-right">
                                <i class="el-icon-close cursor-pointer" @click="removeSupplier(item)"></i>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                    <!-- discount_item_type == brand -->
                    <div v-else-if="discountForm.discount_item_type === 'brand'">
                      <div>
                        <el-button icon="el-icon-search" @click="visibleBrandDialog = true">Брэнд сонгох</el-button>
                      </div>
                      <div v-if="discountForm.brands.length > 0">
                        <div v-for="item in discountForm.brands" :key="item.brand_id">
                          <el-row :gutter="20" class="border-top pt20 mt20">
                            <el-col :span="20">
                              {{item.brand_monName}}
                            </el-col>
                            <el-col :span="4">
                              <div class="text-right">
                                <i class="el-icon-close cursor-pointer" @click="removeBrand(item)"></i>
                              </div>
                            </el-col>
                          </el-row>
                        </div>
                      </div>
                    </div>
                    <div class="panel-item" v-if="discountForm.discount_item_type !== 'all_product'">
                      <el-form-item label="Доод хязгаар">
                        <el-input v-model="discountForm.minimum_count" type="number" :min="1" style="width: 300px">
                          <template slot="append">ш</template>
                        </el-input>
                        <span class="tip-text">Хамгийн багадаа хичнээн бүтээгдэхүүн авч байж урамшуулалал ажиллах хязгаар</span>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Minimum requirement -->
              <div v-if="activeStep===2" class="panel">
                <div class="panel-item">
                  <header>Худалдан авалтын дүнгийн хязгаар</header>
                </div>
                <div class="panel-item">
                  <el-radio-group v-model="discountForm.minimum_requirment_type">
                    <el-radio class="block mb20" v-for="type in minimumRequirementTypes" :key="type.value" :label="type.value">{{type.label}}</el-radio>
                  </el-radio-group>
                  <el-form-item v-if="discountForm.minimum_requirment_type === 'quantity' || discountForm.minimum_requirment_type === 'amount'">
                    <el-row>
                      <el-col :span="12">
                        <el-form-item label="Доод хязгаар">
                          <el-input v-model="discountForm.min_pay_total" type="number" style="width: 200px">
                            <template slot="append">₮</template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="12">
                          <el-form-item label="Дээд хязгаар">
                            <el-input v-model="discountForm.max_pay_total" type="number" style="width: 200px">
                              <template slot="append">₮</template>
                            </el-input>
                          </el-form-item>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </div>
              </div>
              <!-- Usage limit -->
              <div v-if="activeStep===3" class="panel">
                <div class="panel-item">
                  <header>Хэрэглээний хязгаар</header>
                </div>
                <div class="panel-item">
                  <div>
                    <el-checkbox v-model="discountForm.usage_limit_check">Ашиглагдах хязгаар тавих</el-checkbox>
                  </div>
                  <div class="mt10">
                    <el-input v-if="discountForm.usage_limit_check" type="number" v-model="discountForm.usage_limit" min="0" class="ml30" style="width: 200px"></el-input>
                  </div>
                  <div class="mt10">
                    <el-checkbox v-model="discountForm.one_user_limit">Нэг хэрэглэгч нэг удаа хэрэглэх</el-checkbox>
                  </div>
                </div>
              </div>
              <!-- Start date, ene date -->
              <div v-if="activeStep === 2" class="panel">
                <div class="panel-item">
                  <header>Идэвхижих огноо</header>
                </div>
                <div class="panel-item">
                  <el-form-item label="Эхлэх огноо">
                    <el-date-picker
                      value-format="yyyy-MM-dd HH:mm:ss"
                      v-model="discountForm.start_date"
                      type="datetime"
                      placeholder="Огноо сонгоно уу">
                    </el-date-picker>
                  </el-form-item>
                  <div>
                    <el-checkbox v-model="discountForm.set_end_date" @change="setEndDateCheck">Дуусах огноо оруулах</el-checkbox>
                  </div>
                  <el-form-item v-if="discountForm.set_end_date" label="Дуусах огноо" class="mt20">
                    <el-date-picker
                      value-format="yyyy-MM-dd HH:mm:ss"
                      v-model="discountForm.end_date"
                      type="datetime"
                      placeholder="Огноо сонгоно уу">
                    </el-date-picker>
                  </el-form-item>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <header>Мэдээлэл</header>
                  <ul class="pl20">
                    <li>Нэр: {{ discountForm.title_mon }}</li>
                    <li>Хэлбэр: {{ discountTypes[discountTypes.findIndex(x => x.value === discountForm.type)].label }}</li>
                    <li>Нөхцөл: {{ discountItemTypes[discountItemTypes.findIndex(x => x.value === discountForm.discount_item_type)].label }}</li>
                    <li v-if="discountForm.discount_item_type !== 'all_product'">Доод хязгаар: {{discountForm.minimum_count}}</li>
                    <li>Идэвхижиж эхлэх огноо: {{ discountForm.start_date }}</li>
                    <li>Төрөл: {{ discountSubTypes[discountSubTypes.findIndex(x => x.value ===discountForm.discount_type)].label }} <span v-if="discountForm.discount_type === 'PERCENTAGE' || discountForm.discount_type === 'FIXED_AMOUNT'">{{discountForm.discount_type === 'FIXED_AMOUNT' ? discountForm.discount.saled_price : discountForm.discount.saled_percentage }}</span> </li>
                  </ul>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="text-right border-top pt20">
            <el-button type="default" @click="confirmLeaveForm = true"
              >Болих</el-button
            >
            <el-button v-if="activeStep > 1" type="warning" @click="prevStep" icon="el-icon-arrow-left">Өмнөх</el-button>
            <el-button v-if="activeStep < 3" type="primary" @click="nextStep">Дараах <i class="el-icon-arrow-right el-icon-right"></i></el-button>
            <el-button v-if="activeStep===3"  type="success" @click="saveDiscount('discountForm')">Хадгалах</el-button>
          </div>
        </el-col>
      </el-row>
    </el-form>
    <!-- free Product search dialog -->
    <product-search-dialog v-if="visibleProductDialog1" :visible-product-dialog.sync="visibleProductDialog1" :products.sync="discountForm.freeProducts"></product-search-dialog>
    <!-- Product search dialog -->
    <product-search-dialog v-if="visibleProductDialog" :visible-product-dialog.sync="visibleProductDialog" :products.sync="discountForm.products"></product-search-dialog>
    <!-- Supplier search dialog -->
    <supplier-search-dialog v-if="visibleSupplierDialog" :visible-supplier-dialog.sync="visibleSupplierDialog" :suppliers.sync="discountForm.suppliers"></supplier-search-dialog>
    <!-- Brand search dialog -->
    <brand-search-dialog v-if="visibleBrandDialog" :visible-brand-dialog.sync="visibleBrandDialog" :brands.sync="discountForm.brands"></brand-search-dialog>
    <!-- User group search dialog -->
    <user-group-search-dialog v-if="visibleUserGroupDialog" :visible-user-group-dialog.sync="visibleUserGroupDialog" :user-goups.sync="discountForm.discount_user_groups"></user-group-search-dialog>
    <!-- User search dialog -->
    <user-search-dialog v-if="visibleUserDialog" :visible-user-dialog.sync="visibleUserDialog" :users.sync="discountForm.discount_users"></user-search-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/order">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import helper from '../../../helpers/helper'
import CustomHeader from '../../../components/common/customHeader'
import services from '../../../helpers/services'
import ProductSearchDialog from './components/productSearchDialog'
import SupplierSearchDialog from './components/supplierSearchDialog'
import BrandSearchDialog from './components/brandSearchDialog'
import UserGroupSearchDialog from './components/userGroupSearchDialog'
import UserSearchDialog from './components/userSearchDialog'
export default {
  name: 'addOrder',
  components: {
    CustomHeader,
    ProductSearchDialog,
    SupplierSearchDialog,
    BrandSearchDialog,
    UserGroupSearchDialog,
    UserSearchDialog
  },
  data () {
    return {
      activeStep: 1,
      confirmLeaveForm: false,
      visibleProductDialog: false,
      visibleProductDialog1: false,
      visibleCategoryDialog: false,
      visibleSupplierDialog: false,
      visibleBrandDialog: false,
      visibleUserGroupDialog: false,
      visibleUserDialog: false,
      addressLoading: false,
      addressQuery: '',
      categories: [],
      treeFilterText: '',
      treeDefaultProps: {
        children: 'childrens',
        label: 'name_mon'
      },
      discountTypes: [
        {
          value: 'DISCOUNT_CODE',
          label: 'Купон код'
        },
        {
          value: 'AUTO',
          label: 'Автоматаар бодогдох'
        }
      ],
      discountSubTypes: [
        {
          value: 'PERCENTAGE',
          label: 'Хувиар %'
        },
        {
          value: 'FIXED_AMOUNT',
          label: 'Үнийн дүнгээр'
        },
        {
          value: 'FREE_DELIVERY',
          label: 'Хүргэлтийн үнэ'
        },
        {
          value: 'PRODUCT',
          label: 'Бүтээгдэхүүн'
        }
      ],
      discountItemTypes: [
        {
          value: 'all_product',
          label: 'Бүх бүтээгдэхүүн'
        },
        {
          value: 'specific_product',
          label: 'Сонгосон бүтээгдэхүүн'
        },
        {
          value: 'category',
          label: 'Сонгосон ангилалын бүх бүтээгдэхүүн'
        },
        {
          value: 'supplier',
          label: 'Сонгосон нийлүүлэгчийн бүх бүтээгдэхүүн'
        },
        {
          value: 'brand',
          label: 'Сонгосон брендийн бүх бүтээгдэхүүн'
        }
      ],
      minimumRequirementTypes: [
        {
          value: 'none',
          label: 'Шаардлагагүй'
        },
        {
          value: 'amount',
          label: ' Тохируулах'
        }
      ],
      discountUserTypes: [
        {
          value: 'all',
          label: 'Бүх хэрэглэгчид'
        },
        {
          value: 'user_group',
          label: 'Хэрэглэгчидийн групп'
        },
        {
          value: 'user',
          label: 'Сонгогдсон хэрэглэгч'
        }
      ],
      discountForm: {
        type: 'DISCOUNT_CODE',
        minimum_count: 1,
        title_mon: '',
        title_eng: '',
        desc_mon: '',
        desc_eng: '',
        channel: 'TOKTOKMALL',
        discount_code: '',
        discount_type: 'PERCENTAGE',
        min_pay_total: 0,
        max_pay_total: 0,
        discount: {},
        freeProducts: [],
        products: [],
        categories: [],
        suppliers: [],
        brands: [],
        discount_item_type: 'all_product',
        minimum_requirment_type: 'none',
        minimum_requirement_value: 0,
        discount_user_type: 'all',
        discount_user_groups: [],
        discount_users: [],
        usage_limit_check: false,
        usage_limit: 0,
        one_user_limit: true,
        start_date: new Date(),
        end_date: null,
        set_end_date: false
      },
      discountFormRules: {
        type: [
          { required: true, message: 'Хямдралийн төрөл сонгоно уу', trigger: 'blur' }
        ],
        discount_code: [
          { required: true, message: 'Хямдралийн код оруулна уу', trigger: ['blur', 'change'] }
        ],
        title_mon: [
          { required: true, message: 'Монгол нэр оруулна уу', trigger: 'blur' }
        ],
        title_eng: [
          { required: true, message: 'Англи нэр оруулна уу?', trigger: 'blur' }
        ],
        channel: [
          { required: true, message: 'Та цацах сувгаа сонгоно уу?', trigger: 'blur' }
        ],
        desc_mon: [
          { required: true, message: 'Монгол тайлбар оруулна уу', trigger: 'blur' }
        ],
        desc_eng: [
          { required: true, message: 'Англи тайлбар оруулна уу', trigger: 'blur' }
        ]
      },
      errorArray: [],
      suggestedAddresses: []
    }
  },
  created () {
    if (this.$route.query.type === 'DISCOUNT_CODE') {
      this.discountForm.type = 'DISCOUNT_CODE'
    } else {
      this.discountForm.type = 'AUTO'
    }
    this.getCategories()
  },
  watch: {
    treeFilterText (val) {
      this.$refs.tree.filter(val)
    }
  },
  methods: {
    prevStep () {
      this.activeStep--
    },
    nextStep () {
      if (this.activeStep === 1) {
        this.$refs.discountForm.validate((valid) => {
          if (valid) {
            this.activeStep++
          }
        })
      } else if (this.activeStep === 2) {
        // Discount-ийн нөхцөлүүд
        if (this.discountForm.discount_item_type === 'specific_product') {
          const tempProduct = []
          this.discountForm.products.forEach(product => {
            product.variants.forEach(variant => {
              tempProduct.push({ product_id: variant.product_id, variant_id: variant.variant_id, min_count: this.discountForm.minimum_count })
            })
          })
          this.discountForm.products = tempProduct
          this.discountForm.categories = []
          this.discountForm.suppliers = []
          this.discountForm.brands = []
        } else if (this.discountForm.discount_item_type === 'category') {
          const checkedNodes = this.$refs.tree.getCheckedNodes()
          const tempCategories = []
          checkedNodes.forEach(category => {
            tempCategories.push({ category_id: category.uniq_id, min_count: this.discountForm.minimum_count })
          })
          this.discountForm.categories = tempCategories
          this.discountForm.products = []
          this.discountForm.suppliers = []
          this.discountForm.brands = []
        } else if (this.discountForm.discount_item_type === 'supplier') {
          const tempSuppliers = []
          this.discountForm.suppliers.forEach(supplier => {
            tempSuppliers.push({ supplier_id: supplier.supplier_id, min_count: this.discountForm.minimum_count })
          })
          this.discountForm.categories = []
          this.discountForm.products = []
          this.discountForm.suppliers = tempSuppliers
          this.discountForm.brands = []
        } else if (this.discountForm.discount_item_type === 'brand') {
          const tempBrands = []
          this.discountForm.brands.forEach(brand => {
            tempBrands.push({ brand_id: brand.brand_id, min_count: this.discountForm.minimum_count })
          })
          this.discountForm.categories = []
          this.discountForm.products = []
          this.discountForm.suppliers = []
          this.discountForm.brands = tempBrands
        }
        this.activeStep++
      } else {
        this.activeStep++
      }
    },
    setEndDateCheck () {
      if (this.discountForm.set_end_date) {
        this.discountForm.end_date = new Date()
      } else {
        this.discountForm.end_date = null
      }
    },
    getCategories () {
      this.loading = true
      services.getCategoriesAsTree().then(data => {
        this.categories = data
        this.loading = false
      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data.name_mon.indexOf(value) !== -1
    },
    generateCode () {
      const code = helper.generateDiscountCode()
      this.discountForm.discount_code = code
    },
    saveDiscount (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const payload = JSON.parse(JSON.stringify(this.discountForm))
          payload.start_date = payload.start_date.slice(0, 19).replace('T', ' ')
          if (payload.end_date) {
            payload.end_date = payload.end_date.slice(0, 19).replace('T', ' ')
          }
          if (payload.usage_limit === 0) {
            payload.usage_limit = null
          } else {
            payload.usage_limit = parseInt(payload.usage_limit)
          }
          if (payload.min_pay_total === 0) {
            payload.min_pay_total = null
          } else {
            payload.min_pay_total = parseInt(payload.min_pay_total)
          }
          if (payload.max_pay_total === 0) {
            payload.max_pay_total = null
          } else {
            payload.max_pay_total = parseInt(payload.max_pay_total)
          }
          // Хямдруулах бүтээгдэхүүн
          if (payload.discount_type === 'PRODUCT') {
            const freeProducts = []
            payload.freeProducts.forEach(freeProduct => {
              freeProduct.variants.forEach(variant => {
                freeProducts.push({ product_id: variant.product_id, variant_id: variant.variant_id, count: parseInt(variant.count) })
              })
            })
            payload.discount = { free_products: freeProducts }
          }
          services.addDiscount(payload).then(response => {
            this.alertReporter(response.status === 'success' ? 'Амжилттай' : 'Уучлаарай', response.message)
            if (response.status === 'success') {
              if (this.$route.query.type === 'DISCOUNT_CODE') {
                this.$router.push({ name: 'codeDiscount' })
              } else {
                this.$router.push({ name: 'autoDiscount' })
              }
            } else {
              console.log(response.message)
            }
          })
        } else {
          return false
        }
      })
    },
    deleteVariantFromDiscount (product, delVariant) {
      product.variants = product.variants.filter(variant => variant !== delVariant)
    },
    deleteProductFromDiscount (delProduct) {
      this.discountForm.products = this.discountForm.products.filter(product => product !== delProduct)
    },
    removeSupplier (val) {
      this.discountForm.suppliers = this.discountForm.suppliers.filter(supplier => supplier !== val)
    },
    removeBrand (val) {
      this.discountForm.brands = this.discountForm.brands.filter(brand => brand !== val)
    },
    removeUserGroup (val) {
      this.discountForm.discount_user_groups = this.discountForm.discount_user_groups.filter(item => item !== val)
    },
    removeUser (val) {
      this.discountForm.discount_users = this.discountForm.discount_users.filter(item => item !== val)
    },
    filterAddress (val) {
      if (val.length > 1) {
        services.searchAddress(val).then(response => {
          if (Array.isArray(response) && response.length > 0) {
            this.suggestedAddresses = response
          } else {
            this.suggestedAddresses = []
          }
        })
      }
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    }
  }
}
</script>
